/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'flag': {
    width: 12,
    height: 15,
    viewBox: '0 0 12 15',
    data: '<g _stroke="#1D1D44" _fill="none" fill-rule="evenodd"><path pid="0" stroke-linecap="square" d="M1.288 1v13.5"/><path pid="1" d="M1.288 1.866c1.24.585 2.703.585 4.389 0 1.686-.584 3.153-.472 4.403.336v5.67c-1.489-.51-2.957-.436-4.403.222-1.447.659-2.91.659-4.39 0"/></g>'
  }
})
